<template>
  <div class="modal" :class="{ '--show': isShow }">
    <div class="modal__bg" @click.stop="closeModal"></div>
    <div class="modal__body">
      <button class="modal__close--circle" @click.stop="closeModal"></button>
      <div class="modal__title">{{ items.name }}</div>
      <div class="modal__category">{{ items.category }}</div>
      <img
        :src="require(`@/assets/img/sp/food/modal/${items.id}.jpg`)"
        alt=""
        class="modal__img"
      />
      <p class="modal__desc">{{ items.desc }}</p>
      <div class="modal__map">
        <div class="modal__map-wrap">
          <img
            :src="require(`@/assets/img/sp/food/modal/map/${items.id}.png`)"
            :srcset="`${require(`@/assets/img/sp/food/modal/map/${items.id}@2x.png`)} 2x`"
            alt=""
            class="modal__map-img"
          />
        </div>
      </div>
      <div class="modal__footer">
        <button class="modal__close" @click.stop="closeModal">閉じる</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalFood",
  props: ["isShow", "items"],
  methods: {
    closeModal() {
      this.$emit("from-modal");
    },
  },
};
</script>
