<template>
  <div class="legend --toilet">
    <dl class="legend__wrap">
      <div class="legend__item --1">
        <dt>空</dt>
        <dd>60%未満</dd>
      </div>
      <div class="legend__item --3">
        <dt>満</dt>
        <dd>100%</dd>
      </div>
      <div class="legend__item --2">
        <dt>混</dt>
        <dd>60～100%未満</dd>
      </div>
      <div class="legend__item --0">
        <dt>ー</dt>
        <dd>調整中</dd>
      </div>
    </dl>
    <ul class="legend__notice">
      <li>
        混雑表示は、個室の扉に設置した開閉センサーの情報をもとに実施しております。
      </li>
      <li>混雑表示は実際の状況とタイムラグが発生する場合がございます。</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LegendToilet",
};
</script>
