<template>
  <div class="parking__map">
    <div class="parking__map-wrap">
      <img
        src="@/assets/img/sp/parking/map/map_parking.png"
        srcset="@/assets/img/sp/parking/map/map_parking@2x.png 2x"
        alt=""
        class="parking__map-img"
      />
      <ul v-if="statusList" class="bubble-parking">
        <li
          v-for="(item, index) in statusList"
          class="bubble-parking__item"
          :class="`--${item.no}`"
          :key="index"
        >
          <img
            v-if="item.info.img"
            v-lazy="
              require(`@/assets/img/sp/status/parking/${item.info.img}-m.png`)
            "
            :srcset="`${require(`@/assets/img/sp/status/parking/${item.info.img}-m@2x.png`)} 2x`"
            :alt="item.info.alt"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapParking",
  props: ["statusList"],
};
</script>
