<template>
  <div @click="showModal">
    <img
      class="food__img"
      v-lazy="require(`@/assets/img/sp/food/shop/${items.src}.png`)"
      :srcset="`${require(`@/assets/img/sp/food/shop/${items.src}@2x.png`)} 2x`"
      width="113"
      height="66"
      alt=""
    />
    <img
      class="food__status"
      v-lazy="require(`@/assets/img/sp/status/food/${items.info.img}.png`)"
      :srcset="`${require(`@/assets/img/sp/status/food/${items.info.img}@2x.png`)} 2x`"
      width="113"
      height="17"
      alt=""
    />
    <ModalFood :isShow="isShow" :items="items.modal" @from-modal="closeModal" />
  </div>
</template>

<script>
import ModalFood from "./ModalFood.vue";

export default {
  name: "cardFood",
  components: {
    ModalFood,
  },
  props: ["items"],
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    showModal() {
      this.isShow = true;
      const $body = document.getElementsByTagName("body")[0];
      $body.classList.add("fixed");
    },
    closeModal() {
      this.isShow = false;
      const $body = document.getElementsByTagName("body")[0];
      $body.classList.remove("fixed");
    },
  },
};
</script>
