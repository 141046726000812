import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/sass/style.scss";
import VueGtag from "vue-gtag";
const gtagId = process.env.VUE_APP_GA_ID;

import AxiosPlugin from "./plugins/axios";
Vue.use(AxiosPlugin);

Vue.config.productionTip = false;

import VueMeta from "vue-meta";

Vue.use(VueMeta);

import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload);

Vue.mixin({
  head: {
    title: function () {
      const title = this.$route.meta.title;
      return {
        inner: title ? title : "",
      };
    },
    meta: function () {
      const complement = "";
      const title = this.$route.meta.title;
      const content = title ? `${title} | ${complement}` : complement;
      return [{ name: "og:title", content: content }];
    },
  },
});

Vue.use(
  VueGtag,
  {
    config: { id: gtagId },
  },
  router
);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
