<template>
  <div class="legend">
    <dl>
      <div class="legend__item --1">
        <dt>空</dt>
        <dd>駐車可能です。</dd>
      </div>
      <div class="legend__item --2">
        <dt>混</dt>
        <dd>混雑しています。</dd>
      </div>
      <div class="legend__item --3">
        <dt>満</dt>
        <dd>現在満車です。</dd>
      </div>
      <div class="legend__item --0">
        <dt>-</dt>
        <dd>現在情報がございません。</dd>
      </div>
    </dl>
    <ul class="legend__notice">
      <li>駐車場の混雑状況はリアルタイム情報として更新されています。</li>
      <li>機器に不具合が発生した際は正確な表示ができない場合がございます。</li>
      <li>競馬場へご来場の際は公共交通機関をご利用ください。</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LegendToilet",
};
</script>
