<template>
  <div class="admin-layout">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "AdminLayout",
};
</script>

<style lang="scss" scoped>
.admin-layout {
  font-size: 16px;
}
</style>