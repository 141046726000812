<template>
  <div class="food__row">
    <div v-if="statusList.left" class="food__left">
      <ul class="food__list --left">
        <li
          v-for="(item, index) in statusList.left"
          :key="index"
          class="food__shop"
        >
          <CardFood :items="item" />
        </li>
      </ul>
    </div>
    <div v-if="statusList.right" class="food__right">
      <ul class="food__list --right">
        <li
          v-for="(item, index) in statusList.right"
          :key="index"
          class="food__shop"
        >
          <CardFood :items="item" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CardFood from "../detail/CardFood.vue";

export default {
  name: "FoodList",
  components: {
    CardFood,
  },
  props: {
    statusList: Object,
  },
};
</script>
