import axios from "axios";

const AxiosPlugin = {};

AxiosPlugin.install = function (Vue) {
  Vue.prototype.$axios = axios.create({
    // baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "json",
    crossDomain: true,
    timeout: 5000,
    // withCredentials: false // Cookieの送信を許可
    withCredentials: true, // Cookieの送信を許可
  });
};

export default AxiosPlugin;
